import ContentLoader from "react-content-loader";
import * as React from 'react';

import styles from "./fallback.module.scss";

export const MainPageFallback: React.FunctionComponent = () => {
  return (
    <div className={styles.main}>
      <div className={styles["levo-logo"]}>
        <svg width="109" height="55" viewBox="0 0 109 55" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.30447 38.5H19.8697V40.9444H4.57947V13.75H7.30447V38.5Z" fill="white"/>
          <path d="M59.0794 37.3542H59.1551L67.9357 13.75H70.8499L60.5176 40.9444H57.6412L47.3468 13.75H50.2989L59.0794 37.3542Z" fill="white"/>
          <path d="M104.118 27.3472C104.118 29.4097 103.777 31.3194 103.058 33.0764C102.339 34.8333 101.355 36.3229 100.106 37.5833C98.857 38.8437 97.381 39.8368 95.64 40.5625C93.899 41.2882 92.0824 41.6319 90.0765 41.6319C88.0706 41.6319 86.2161 41.2882 84.5129 40.5625C82.8098 39.8368 81.2959 38.8437 80.047 37.5833C78.798 36.3229 77.814 34.7951 77.0949 33.0764C76.3758 31.3194 76.0352 29.4097 76.0352 27.3472C76.0352 25.2847 76.3758 23.375 77.0949 21.6181C77.814 19.8611 78.798 18.3715 80.047 17.1111C81.2959 15.8507 82.772 14.8576 84.5129 14.1319C86.2161 13.4062 88.0706 13.0625 90.0765 13.0625C92.0824 13.0625 93.9369 13.4062 95.64 14.1319C97.3431 14.8576 98.857 15.8507 100.106 17.1111C101.355 18.3715 102.339 19.8993 103.058 21.6181C103.739 23.3368 104.118 25.2465 104.118 27.3472ZM101.204 27.3472C101.204 25.7431 100.939 24.2535 100.447 22.8021C99.9546 21.3889 99.1977 20.1285 98.2515 19.0208C97.3053 17.9514 96.0942 17.0729 94.7317 16.4236C93.3313 15.7743 91.7796 15.4687 90.0765 15.4687C88.3355 15.4687 86.7838 15.7743 85.4213 16.4236C84.0209 17.0729 82.8477 17.9132 81.9015 19.0208C80.9553 20.0903 80.1984 21.3507 79.7063 22.8021C79.2143 24.2535 78.9494 25.7431 78.9494 27.3472C78.9494 28.9514 79.2143 30.441 79.7063 31.8924C80.1984 33.3438 80.9553 34.566 81.9015 35.6354C82.8477 36.7049 84.0209 37.5833 85.4213 38.1944C86.8216 38.8438 88.3734 39.1493 90.0765 39.1493C91.8174 39.1493 93.3692 38.8438 94.7317 38.1944C96.132 37.5451 97.3053 36.7049 98.2515 35.6354C99.1977 34.566 99.9546 33.3056 100.447 31.8924C100.939 30.441 101.204 28.9514 101.204 27.3472Z" fill="white"/>
          <path d="M27.7798 16.1944H41.7454V13.75H25.017V16.1944H27.7798Z" fill="#FF8B00"/>
          <path d="M27.7798 38.5V27.9965H40.8371V25.5139H30.5427H27.7798H25.017V40.9444H42.1996V38.5H27.7798Z" fill="white"/>
          </svg>
          
      </div>
      <div className={styles["human-background"]}>
        <div className={styles["login-human"]}>
          <svg width="188" height="248" viewBox="0 0 188 248" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M102.197 43.6724C98.4413 45.9378 94.751 47.0324 93.0187 46.5856C88.6749 45.4651 88.2755 29.5631 91.6365 23.2941C94.9975 17.025 112.276 14.2926 113.147 26.7136C113.449 31.0245 111.64 34.8718 108.968 38.011L113.76 60.3624H99.8396L102.197 43.6724Z" fill="#D9A37D"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M97.1348 16.4662C99.0369 15.8858 101.798 15.6889 102.96 15.8221C102.299 14.2379 102.037 12.4729 102.25 10.7544C102.458 9.0846 103.204 7.66278 103.893 6.17288C104.211 5.48613 104.571 4.80313 105.109 4.27785C105.656 3.74269 106.348 3.38945 107.074 3.22092C107.818 3.04841 108.595 3.06278 109.339 3.21933C109.668 3.28857 109.964 3.43923 110.287 3.52298C110.617 3.60879 110.778 3.40166 111.018 3.1935C111.596 2.69194 112.444 2.44434 113.176 2.35573C113.997 2.25635 114.849 2.38029 115.632 2.65039C116.394 2.91334 117.127 3.34032 117.678 3.96236C117.923 4.23925 118.146 4.55441 118.291 4.90237C118.35 5.04516 118.36 5.12975 118.502 5.18206C118.634 5.23049 118.788 5.23189 118.924 5.27798C120.238 5.72339 121.362 6.73952 121.973 8.04419C122.33 8.80533 122.499 10.1454 122.453 11.6474C122.407 13.1495 121.051 17.0687 119.784 18.4982C118.652 19.7742 116.989 20.6163 115.384 20.9848C116.45 22.2034 117.159 23.7262 117.683 25.2748C118.253 26.9571 118.605 28.705 118.735 30.486C118.859 32.1781 118.871 33.9675 118.474 35.6235C118.103 37.1721 117.31 38.4425 115.986 39.2485C115.38 39.6177 114.727 39.8951 114.071 40.1486C113.425 40.3984 112.776 40.6412 112.098 40.7814C110.816 41.0466 109.339 41.0011 108.351 39.9798C107.995 39.6116 107.604 39.149 107.344 38.6992C107.251 38.5375 107.194 38.4317 107.308 38.2736C107.444 38.0842 107.664 37.9392 107.838 37.791C108.834 36.9465 109.45 35.819 109.605 34.4791C109.763 33.1196 109.398 31.3648 108.077 30.7715C106.447 30.0391 104.651 30.8798 103.202 31.6634C102.586 29.4882 101.322 27.5296 99.3389 26.5318C97.1454 25.428 94.6284 25.2988 92.3743 24.3517C91.787 24.1049 91.1887 23.8247 90.7562 23.3218C90.5755 23.1117 90.3635 22.839 90.2913 22.5611C90.1966 22.1959 90.4319 21.8898 90.6137 21.6052C91.5775 20.0958 95.2327 17.0466 97.1348 16.4662Z" fill="#7D5525"/>
            <ellipse cx="91.3558" cy="210.698" rx="91.0319" ry="6.4107" fill="url(#paint0_radial_25_235)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M114.92 210.107C90.2549 210.107 70.2599 191.008 70.2599 167.447C70.2599 143.887 90.2549 124.787 114.92 124.787C139.585 124.787 159.58 143.887 159.58 167.447C159.58 191.008 139.585 210.107 114.92 210.107Z" fill="#C5CFD6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M45.4875 165.172C43.3303 172.734 37.9559 187.066 30.5245 203.525L23.4628 201.464C29.6408 163.589 34.7824 138.514 37.7276 130.883C39.6734 125.84 54.395 126.431 52.7014 136.577C51.9912 140.832 49.1812 152.225 45.4875 165.172ZM74.5129 138.346C86.0289 140.734 109.906 149.174 116.725 151.871C121.01 153.566 118.247 159.315 114.592 158.812C113.973 158.727 111.782 158.481 108.515 158.116C98.0178 156.94 76.4011 154.52 60.0063 152.159C51.3736 150.916 56.0174 134.734 64.9806 136.447C67.9962 137.023 71.2036 137.66 74.5129 138.346Z" fill="#D9A37D"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.276 126.609C38.4534 122.764 91.9738 109.116 91.9738 109.116H96.3599V113.206L59.4793 131.994C59.5283 132.6 59.4983 133.241 59.3793 133.917C59.6457 147.845 53.6889 158.249 47.7912 168.551C43.9345 175.288 40.1031 181.98 38.0538 189.585L24.504 187.562C24.8987 186.552 25.5228 181.997 26.3762 175.77C28.5278 160.068 32.1368 133.731 37.1998 126.763C37.2175 126.738 37.2355 126.714 37.2538 126.69C37.2603 126.663 37.2677 126.636 37.276 126.609Z" fill="#0052CC"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M93.2957 143.152C96.6702 142.476 99.7777 141.873 102.437 141.406C119.613 138.391 124.621 129.191 121.66 109.116H90.0137C84.8443 110.602 57.979 121.877 44.04 127.873C34.8145 131.842 37.7227 146.149 42.9471 149.872C42.9755 150.101 43.0695 150.256 43.237 150.325C63.0445 158.541 79.9355 157.928 90.7821 157.534C95.325 157.369 98.8076 157.243 101 157.82L104.435 146.976L93.2957 143.152Z" fill="#0065FF"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M113.053 152.221C113.145 151.054 114.127 150.112 115.287 150.267C116.385 150.415 117.63 150.644 118.315 150.978C119.725 151.666 122.201 153.457 122.201 153.457C121.403 155.094 107.86 182.88 107.86 182.88C107.86 182.88 103.512 182.177 104.415 179.559C105.317 176.94 105.87 175.187 105.87 175.187L106.428 158.969C106.448 158.406 106.928 157.971 107.49 158.006L109.618 158.14C109.618 158.14 111.8 156.373 112.497 154.943C112.817 154.287 112.975 153.202 113.053 152.221Z" fill="#0747A6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M31.4544 200.178C32.5444 199.749 33.8221 200.219 34.191 201.33C34.5403 202.383 34.8799 203.602 34.8799 204.365C34.8799 205.935 34.3572 208.946 34.3572 208.946C32.5372 208.946 1.64409 208.946 1.64409 208.946C1.64409 208.946 0.368946 204.728 3.11627 204.392C5.86359 204.055 7.68076 203.784 7.68076 203.784L22.4905 197.177C23.0052 196.948 23.6078 197.189 23.8225 197.71L24.6352 199.682C24.6352 199.682 27.1785 200.87 28.7686 200.87C29.4973 200.87 30.5393 200.538 31.4544 200.178Z" fill="#0747A6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M47.246 114.709L65.747 101.114L69.8369 111.231L51.516 118.833C46.3346 124.924 43.4372 127.463 42.8239 126.451C42.3068 125.597 42.711 124.798 43.0833 124.061C43.3734 123.488 43.6441 122.953 43.4448 122.46C42.9897 121.334 39.5091 121.453 36.211 121.706C32.9128 121.96 33.874 120.492 34.6693 119.766C38.0359 117.75 42.2282 116.064 47.246 114.709ZM144.269 138.664C142.57 135.214 136.782 101.583 136.782 101.583L124.661 101.631C124.661 101.631 135.887 137.895 136.782 140.114C137.944 142.996 135.97 147.184 134.631 150.024C134.424 150.463 134.232 150.87 134.069 151.235C135.927 152.067 136.819 151.062 137.76 150.002C138.822 148.805 139.947 147.538 142.593 148.77C143.616 149.246 144.583 149.809 145.529 150.359C148.794 152.258 151.794 154.003 155.875 151.483C156.522 151.084 157.23 149.58 155.518 148.404C151.25 145.476 145.1 140.351 144.269 138.664Z" fill="#D9A37D"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M102.337 50.6885L97.3218 49.8277C78.7135 65.9139 71.0703 96.8175 48.2611 113.188L53.7511 119.998C93.321 115.095 103.129 77.4005 102.337 50.6885Z" fill="#E87613"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M124.2 113.76C124.2 113.76 94.0358 113.76 79.8235 113.76C77.7957 113.76 78.1778 110.83 78.471 109.351C81.854 92.2872 95.0602 73.8928 95.0602 49.6023L107.861 47.5936C118.448 64.6335 122.109 85.613 124.2 113.76Z" fill="#DDE3E9"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M122.5 64.1426C124.829 63.9761 126.931 63.0007 128.26 60.6012C132.939 52.1533 131.249 47.5021 126.249 45.9732C123.5 45.1325 120.914 45.7063 117.819 46.3933C115.285 46.9555 112.41 47.5936 108.824 47.5936C108.477 47.5936 108.161 47.6169 107.874 47.6615L103.627 47.904C103.627 47.904 86.3813 106.392 94.1143 125.949H129.19C131.185 130.742 133.14 134.925 134.983 138.137H144.5C145.524 105.007 132.99 79.5139 122.5 64.1426Z" fill="#FF9B21"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M129.19 125.949C126.573 119.659 123.89 112.319 121.308 104.737C120.346 113.086 118.746 121.472 116.08 125.949H129.19Z" fill="black" fillOpacity="0.1"/>
            <defs>
            <radialGradient id="paint0_radial_25_235" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(91.3558 210.698) rotate(90) scale(6.4107 91.0319)">
            <stop stopColor="#07031F" stopOpacity="0.2"/>
            <stop offset="1" stopColor="#6554C0" stopOpacity="0"/>
            </radialGradient>
            </defs>
            </svg>
        </div>
        <div id="white-vector">  
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1279.34 236.48"><g id="Capa_2" data-name="Capa 2"><g id="svg12"><path d="M1279.34,236.48H0V224.73c236.41,18.72,410.56-18.37,524.41-54.63C732.13,103.93,898.38-10.22,1169,.74a920.58,920.58,0,0,1,110.35,11.15Z" style={{ fill:"#fff" }}/></g></g></svg>
        </div>
      </div>
      <div className={styles["login-container"]}>
        <div className={styles["login-box"]}>
          <ContentLoader 
            speed={2}
            width={624}
            height={644}
            viewBox="0 0 624 644"
            backgroundColor="#f3f3f3"
            foregroundColor="#d8d7d9"
          >
            <rect x="217" y="30" rx="0" ry="0" width="189" height="24" /> 
            <rect x="89" y="109" rx="0" ry="0" width="45" height="18" /> 
            <rect x="87" y="138" rx="4" ry="4" width="450" height="65" /> 
            <rect x="171" y="378" rx="4" ry="4" width="300" height="60" /> 
            <rect x="181" y="461" rx="0" ry="0" width="279" height="18" /> 
            <rect x="40" y="558" rx="4" ry="4" width="260" height="62" /> 
            <rect x="323" y="558" rx="4" ry="4" width="260" height="62" /> 
            <rect x="92" y="227" rx="0" ry="0" width="83" height="18" /> 
            <rect x="87" y="256" rx="4" ry="4" width="450" height="65" />
          </ContentLoader>
        </div>
      </div>
    </div>
  );
};
