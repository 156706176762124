import React, { useState } from "react"; 

import { init, shutdown } from "@fullstory/browser";
import SentryFullStory from "@sentry/fullstory";
import * as Sentry from "@sentry/react";
import { Spin } from "antd";
import ReactDOM from "react-dom";
import { lazy } from "src/utils/lazy";

import { MainPageFallback } from "./index.fallback";
import './index.scss';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import("./App"));

// TODO: Remove this code once ant.design removes all findDOMNode usages
// eslint-disable-next-line
const consoleError = console.error.bind(console);
// @ts-ignore
console.error = (errObj, ...args) => {
  if (window.__env__.NODE_ENV === 'development' && (typeof errObj === 'string' || typeof errObj.message === 'string') && args.includes('findDOMNode')){
    return;
  }
  consoleError(errObj, ...args);
};

if (window.__env__.REACT_APP_FULLSTORY_ORG_ID) {
  const baseUrl = (() => {
    switch (window.__env__.REACT_APP_LEVO_ENV) {
      case 'dev':
        return 'app.dev.levo.ai';
      case 'ganga':
        return 'app.india-1.levo.ai';
      default:
        return 'app.levo.ai';
    }
  })();
  init({
    orgId: window.__env__.REACT_APP_FULLSTORY_ORG_ID,
    debug: true,
    host: `${baseUrl}/fsrelay`,
    script: `${baseUrl}/fsrelay/s/fs.js`,
    namespace: window.__env__.REACT_APP_LEVO_ENV,
  });
  // Prevent recording unless we manually start the recording after identifying the user
  shutdown();
}

if (window.__env__.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: window.__env__.REACT_APP_SENTRY_DSN,
    integrations: [
      ...(
          window.__env__.REACT_APP_FULLSTORY_ORG_ID && window.__env__.REACT_APP_SENTRY_ORG_ID
          ? [new SentryFullStory(window.__env__.REACT_APP_SENTRY_ORG_ID)]
          : []
      ),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: window.__env__.REACT_APP_LEVO_ENV === "prod" ? 0.1 : 1.0,
    environment: window.__env__.NODE_ENV === "development"
      ? "local"
      : window.__env__.REACT_APP_LEVO_ENV === "dev"
        ? "development"
        : "production"
  });
}



const LazyApp: React.FunctionComponent = () => {
  const [jwt] = useState(localStorage.getItem("jwt"));
  return (
    <React.Suspense fallback={(
      <>
        {jwt
          ? <Spin />
          : <MainPageFallback />
        }
      </>)
    }>
      <App />
    </React.Suspense>
  );
};

ReactDOM.render( 
  <React.StrictMode> 
    <LazyApp /> 
  </React.StrictMode>
, document.getElementById("root") 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
